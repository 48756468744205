import React, { Component } from "react";
import { Loading } from "../LoadingComponent";
import {Link, withRouter} from "react-router-dom";
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css"
import NumberFormat from "react-number-format";
import ReactImageMagnify from 'react-image-magnify';
import { withCookies } from "react-cookie";
import { compose } from "redux";
import _ from 'lodash';
import {Helmet} from "react-helmet";
import { Event } from "../Tracking/Event"
import ReactHtmlParser from 'react-html-parser';
import {isEmpty} from "lodash";
import Snackbar from '@material-ui/core/Snackbar';

import {
  FormGroup,
  Input,
  Row,
  Col,
  Card,
  CardImg,
  Button,
  TabContent, Nav, NavItem, NavLink, Alert, BreadcrumbItem, Breadcrumb
} from "reactstrap";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import onImage from "../../assets/images/no-image.jpg";
import {Ports} from '../../shared/port_country';


class ProductDetailsComponent extends Component {
  constructor(props) {
    super(props);
    this.handleAddToCartUnauthenticated = this.handleAddToCartUnauthenticated.bind(this);
    this.handleAddToCartAuthenticated = this.handleAddToCartAuthenticated.bind(this);
    this.search = this.search.bind(this);
    this.buyNowRef = React.createRef();
    this.handleBreadCrumb = this.handleBreadCrumb.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleHide = this.handleHide.bind(this);

    const { cookies } = props;
    this.state = {
      selectedIndex: 0,
      selectedValue: 'RORO',
      selectedPort: [],
      countries: [],
      ports: [],
      insurance: true,
      currSlide: 0,
      shippingCharge: 0,
      shippingCode: [],
      email: cookies.get("email") || "",
      userLoggedIn: !!cookies.get("email"),
      vehicleID: "",
      message: "",
      selectedAttr: "",
      destinationCountry: "Kenya",
      destinationPort: "Mombasa",
      url: "",
      shippingOptionDetail: "RORO: Your vehicle will Roll ON / Roll OFF ship vessel",
      optionsData: [],
      hasOptions: false,
      selectedOption: {},
      addToBagNotification: false,
      count: 1
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.email !== this.state.email) {
      const email = nextProps.cookies.get("email");
      this.setState({
        email: email || "",
        userLoggedIn: !!email
      });
    }

  }

  componentDidUpdate(prevProps) {
    let { getVehicleDetail, addProductToBasketSuccess, addProductToBasketError, } = this.props;

    if (addProductToBasketError !== null && prevProps.addProductToBasketError !== addProductToBasketError) {
      if (addProductToBasketError === "AjaxError: ajax error 406") {
        this.buyNowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        this.setState({message: "You have items awaiting checkout. Please proceed to checkout the outstanding items first or remove them from your basket"})
      } else if(addProductToBasketError === "AjaxError: ajax error 401") {
          this.props.history.push(`/login`);
      } else if(addProductToBasketError === "Item is unavailable") {
        this.buyNowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        this.setState({message: "This product is out of stock "})
      } else {
        this.setState({message: addProductToBasketError})
      }
    }


    if (getVehicleDetail && prevProps.getVehicleDetail !== getVehicleDetail) {
      this.setState({ url: getVehicleDetail.url})
      if (getVehicleDetail.options === undefined) {
        this.setState({hasOptions: false})
      }
      else {
        if (getVehicleDetail.options.length === 0) {
          this.setState({hasOptions: false})
        }
        else {
          this.setState({
            optionsData: getVehicleDetail.options,
            hasOptions: true
          })
        }
      }
    } else if (addProductToBasketSuccess && prevProps.addProductToBasketSuccess !== addProductToBasketSuccess) {
      this.props.history.push(`/my-basket`);
    }
  }

  responsive = {
      0: { items: 2 },
      300: { items: 3 },
      400: { items: 3 },
      500: { items: 4 },
      600: { items: 4 },
      700: { items: 4 },
      800: { items: 4 },
      1024: { items: 4 },
      2048: { items: 5 },
  }

  stagePadding = {
      paddingLeft: 0,
      paddingRight: 40,
  }

  body_type_suv = [
      'convertible','sports', '4x4', 'double cab','pick-up', 'touring', 'pickup', 'two door saloon gt shell', '3 door',
      'suv', 'stationwagon', 'low loader', 'pick up with canopy', 'four wheel drive', 'double cab pickup', 'mpv',
      'light 4x4 utility',

  ]

  body_type_sedan = [
      'four wheel drive hatchback', '5 door hatchback', 'sport back', 'hatch','2 door saloon', 'grand coupe', 'saloon',
      'hatchback', '5 door hatch', 'cabriolet // convertible', '4 door saloon', 'roadster','sedan', 'estate',


  ]


  componentDidMount() {
      document.body.scrollTop = 0
      window.scrollTo(0,0);

      const { getVehicle, getCountries, getPromotionalApiData, getShippingMethods } = this.props;
      let id = window.location.pathname.split('/')[2];
      getVehicle(id);
      getCountries();
      getPromotionalApiData();
      getShippingMethods();
      this.setState({vehicleID: id})
  };

  slideTo = (i) => this.setState({ currSlide: i });

  handleBreadCrumb(type, value) {

  if (type === "cat") {
    if (value === "Men") {
      this.props.history.push("/search/cat-search-men");
    }
    if (value === "Women") {
      this.props.history.push("/search/cat-search-women");
    }
  }
  }

  handleMakeChange(e) {
      this.setState({ [e.target.name]: e.target.value,  });
  }

  handlePorts = (e) => {
    this.setState({
      selectedPort: e.target.value,
      shippingCharge: 700
    }, () => {this.updateShippingCode()})
  }

  updateShippingCode = () => {
      const { getShippingMethodsSuccess } = this.props
      const shippingCodesFromMethods = getShippingMethodsSuccess.map(shippingObject => shippingObject.code)
      let veh_type = 'other'
      if (!(typeof this.state.selectedPort === 'string')) {
          return;
      }
      let selectedPort = this.state.selectedPort.split(" ").join("-").toLowerCase();
      let portMatches = _.filter(shippingCodesFromMethods, function (s) {
          return s.indexOf(selectedPort) !== -1;
      });
      if (this.props.getVehicleDetail.specs.body_type !== null ) {
          if (new RegExp(this.body_type_sedan.join("|")).test(this.props.getVehicleDetail.specs.body_type.toLowerCase())) {
              veh_type = 'sedan'
          } else if (new RegExp(this.body_type_suv.join("|")).test(this.props.getVehicleDetail.specs.body_type.toLowerCase())) {
              veh_type = 'suv'
          }
      }

      let codeMatch = _.filter(portMatches, function (s) {
          return s.indexOf(veh_type) !== -1;
      });

      this.setState({
          shippingCode: codeMatch,
          message: ""
      }, () => {this.logIt()})
  }

  logIt()  {
  }

  handleCountryChange(e) {
    let Port = Ports.filter(x => x[e.target.value]);
    if(Port.length > 0)
      Port = Port[0][e.target.value]
    this.setState({ countries: e.target.value, ports : Port || []   });
  }

  handleRadios(val) {
    if (val === 'RORO'){
      this.setState({
        selectedIndex: 0, selectedValue: 'RORO', shippingOptionDetail: "RORO: Your vehicle will Roll ON / Roll OFF ship vessel" }, () => {this.logIt()})
      if (this.state.countries.length > 0) {
        this.setState({ shippingCharge: 700})
      }
    }
    else {
      this.setState({ selectedIndex: 1, selectedValue: 'Container', shippingOptionDetail: "Container: Your vehicle will be shipped in a container" })
      if (this.state.countries.length > 0) {
        this.setState({ shippingCharge: 1200})
        }
      }
  }

  handleOptionsChange(event) {
    event.preventDefault();
    let selectedValue = event.target.options[event.target.selectedIndex].text
    let selectedOpt = this.state.optionsData.filter(function(item) {
      return item.name === selectedValue
    })
    this.setState({selectedOption: selectedOpt[0], message: ""})
  }

  handleBuyNow (prodId, prodPrice, prodTitle, mainImage) {
    Event("PRODUCT", "Product added to cart", "PRODUCT_DETAIL_PAGE")
    const { count } = this.state
    const payload = {
      prodId: prodId,
      prodTitle: prodTitle,
      prodPrice: prodPrice,
      numItems: count,
      mainImage: mainImage
    }
    if (this.state.selectedOption) {
      payload["prodOpt"] = this.state.selectedOption
    }
    if (this.props.isLoggedIn) {
      return <Button className='buyNow' size="sm" onClick={() => this.handleAddToCartAuthenticated(payload)}>
        Buy Now
      </Button>
    } else {
      return <Button className='buyNow' size="sm" onClick={() => this.handleAddToCartUnauthenticated(payload)}>
        Buy Now
      </Button>
    }
  }

  goBack = () => {
    this.props.history.goBack();
  }

  handleValidation (payload) {
    const itemCount = payload.numItems || 1
    let available = null
    if (this.props.getVehicleDetail) {
      available = this.props.getVehicleDetail.get_stock_record.quantity || null
      if (available === null || (parseInt(available) < 1)) {
        this.setState({message: "Sorry, item is out of stock"})
        return;
      } else if (parseInt(available) < itemCount) {
        const notEnoughStock = `Sorry, only ${available} available`
        this.setState({message: notEnoughStock})
        return;
      }
      else {

        this.setState({
          addToBagNotification: true
        })
      }
    }
    const { hasOptions, selectedOption } = this.state;
    if (hasOptions){
      if (isEmpty(selectedOption)) {
        this.setState({message: "Error: Select size"})
        return
      }
    }
  }

  handleAddToCartUnauthenticated (payload) {
    let errors = this.handleValidation(payload)
    if (errors) {
    }
    this.props.addCartProduct(payload)

  }

  handleAddToCartAuthenticated (payload) {
    let errors = this.handleValidation(payload)
    if (errors) {
    }
    // let optionsBaseURL = credentials.API_URL
    // let prodUrl = optionsBaseURL + `/products/${payload['prodId']}/`
    // let optionsUrl = payload['prodOpt']['url']
    this.props.addProductToBasket && this.props.addProductToBasket({
      endpointAndQuantity: JSON.stringify(payload)
    })
  }

  search (nameKey, myArray){
    for (var i=0; i < myArray.length; i++) {
      if (myArray[i].code === nameKey) {
        return myArray[i];
      }
    }
  }

  handleHide= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      addToBagNotification: false
    })
  };

  handleClick = (e) => {
    // Use updater function when new state is derived from old
    const maxItems = 25
    const minItems = 1
    const currentCount = this.state.count

    if (e === "Increase") {
      if (currentCount < maxItems) {
        this.setState(prev => ({ count: prev.count + 1 }));
      } else {
        return
      }
    } else {
      if (currentCount === minItems) {
        return
      }
      this.setState(prev => ({ count: prev.count - 1 }));
    }

  };



  render() {
    const { hasOptions } = this.state;
      const { getPriceSuccess, getVehicleDetailLoader, getVehicleDetail, getPromotionalApiDataSuccess,
        getStockRecordSuccess, addProductToBasketLoader } = this.props;
      const {
        currSlide,
        message,
      } = this.state;
      let prodPrice = null;
    // let cat = getVehicleDetail && getVehicleDetail.categories[0]
      if (getPriceSuccess) {
        prodPrice = getPriceSuccess.incl_tax
      }
      let searched = [];
      let _images = [];
      let descr = [];
      let prodID = null;
      let prodTitle = null;
      let mainImage = null;
      let category = null;
 
      if(getVehicleDetail) {
          const { id, images, description_clean, title } = getVehicleDetail;
          _images = images;
          descr = description_clean.split(' - ');
          prodID = id
          prodTitle = title
          mainImage = _images[0].original

      }
      category = getVehicleDetail && getVehicleDetail.categories[0]
      if (getPromotionalApiDataSuccess) {
          const { most_searched: { sedan } } = getPromotionalApiDataSuccess;

          // TODO
          // logic to determine upselling category
          searched = [...sedan];
      }

      let availability = 0

      if (getStockRecordSuccess) {
        availability = getStockRecordSuccess.results[0].num_in_stock - getStockRecordSuccess.results[0].num_allocated

      }

      const pageTitle = getVehicleDetail && getVehicleDetail.title
      const fullTitle = "Ex UK Online: " + pageTitle
      const productClass = getVehicleDetail && getVehicleDetail.product_class
      const classValue = productClass === 'cars' ? '1' : '2'

      return (
        <div ref={topElement => (this.topElement = topElement)}>
          <Helmet>
            <title>{ fullTitle }</title>
            <meta name="description" content="UK Premier exporter" />
          </Helmet>
          <div className="inner-container" id="contactContainer">
            <div className="row">

              {getVehicleDetailLoader ? (
                <div className="offset-5 offset-lg-6" style={{paddingTop: '100px', paddingBottom: '1220px'}}>
                  <Loading />
                </div>
              ) : (
                  <div className="container">
                    <Breadcrumb style={{marginBottom: "10px"}}>
                      <BreadcrumbItem>
                        <Link to="/" className="listedLink">
                          Home
                        </Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>
                        <Link
                            to="#"
                            className="listedLink"
                            onClick={() => this.handleBreadCrumb('cat', category )}
                        >
                          { category }
                          </Link>{" "}
                      </BreadcrumbItem>
                      <BreadcrumbItem active>
                        <Link to="#">
                          { getVehicleDetail && getVehicleDetail.title }
                        </Link>
                      </BreadcrumbItem>
                    </Breadcrumb>
                      <div>
                      <Row className="car-detail-layout">
                          <Col sm={12} md={5} lg={5} className="car-preview-gallery">
                              <div  style={{ marginTop: 10, height: "400px", position: 'relative' }}>
                                  {
                                      getVehicleDetail &&
                                      <ReactImageMagnify {...{
                                        smallImage: {
                                            alt: 'car image',
                                            src: _images.length && getVehicleDetail ? _images[currSlide].original : onImage,
                                            isFluidWidth: true,
                                            height:400,

                                        },
                                        largeImage: {
                                            src: _images.length && getVehicleDetail ? _images[currSlide].original : onImage,
                                            // width: '120%',
                                            height: 600

                                        },
                                        // enlargedImageContainerDimensions: {
                                        //     width: '120%',
                                        //     height: '120%'
                                        // },
                                        style:{maxHeight:"400px", left:"50%", top:"50%", transform:"translate(-50%, -50%)"},
                                        isHintEnabled: true,
                                        imageStyle:{objectFit:"contain",maxWidth:"100%",maxHeight:"400px"},
                                        // enlargedImageContainerStyle:{marginLeft:"60px"},
                                        enlargedImagePosition: 'over',
                                      }} />
                                  }
                              </div>
                              <div className="carousel-wrapper">
                                <AliceCarousel
                                  fadeOutAnimation={true}
                                  slideToIndex={currSlide}
                                  onSlideChanged={(e) => this.setState({ currSlide: e.item })}
                                  buttonsDisabled={true}
                                  onSlideChange={(e) => this.setState({ currSlide: e.item })}
                                  buttonsEnabled={true}
                                  mouseDragEnabled={true}
                                  dotsDisabled={true}
                                  responsive={this.responsive}
                                  stagePadding={this.stagePadding}
                                  ref={(el) => (this.Carousel = el)}
                              >
                                { _images.length && _images.map((d, i) => {
                                  return (
                                    <div key={i}
                                         onClick={
                                           () => this.slideTo(i)} style={currSlide === i
                                      ? { margin: '0 2px', cursor: 'pointer' }
                                      : {margin: '0 2px', cursor: 'pointer' }}>
                                      <Card>
                                        <CardImg
                                          style={{objectFit:"contain", maxWidth:"100%", maxHeight:"100px"}}
                                          width="100%" height={80} src={d.original} alt="Card image cap" />
                                      </Card>
                                    </div>
                                    );
                                })}
                              </AliceCarousel>
                              </div>
                          </Col>
                          <Col className="item-overview-detail" sm={12} md={7} lg={7}>
                              <p style={{ textAlign: 'left',
                                fontWeight: 'bolder',
                                fontSize: '20px',
                                color: 'black',
                                marginBottom: 0,
                                paddingLeft: '5px'}}>
                                { getVehicleDetail && getVehicleDetail.title }
                              </p>
                              <div id="wrapper" style={{ paddingBottom: '5px', paddingLeft: '10px' }}>
                                  <p className="detailPagePrice">
                                    <NumberFormat
                                      value={parseFloat(prodPrice).toFixed(2)}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'£'}
                                      // suffix={'.00'}
                                    />
                                  </p>
                                  <p className="stockdata">
                                    Availability:
                                    <span className={"stock-" + (availability < 1 ? 'unavailable': 'available')}>
                                      { availability < 1 ? ' OUT OF STOCK5': ' IN STOCK' }
                                    </span>
                                  </p>
                                  <p>
                                    <ButtonGroup>
                                      <Button
                                          aria-label="reduce"
                                          onClick={() => this.handleClick("Decrease")}
                                      >
                                        <RemoveIcon
                                            fontSize="inherit"
                                            style={{ fontSize: "12px" }}
                                        />
                                      </Button>
                                      <span style={{width: 16, textAlign: "center", fontWeight: "bold", fontSize: "120%"}}>{this.state.count}</span>
                                      <Button
                                          aria-label="increase"
                                          onClick={() => this.handleClick("Increase")}
                                      >
                                        <AddIcon
                                            fontSize="inherit"
                                            style={{ fontSize: "12px" }}
                                        />
                                      </Button>
                                    </ButtonGroup>
                                  </p>
                              </div>

                              { addProductToBasketLoader ? (
                                <div className="offset-1 offset-lg-1"
                                     style={{paddingTop: '100px', paddingBottom: '1220px'}}>
                                  <Loading />
                                </div>
                              ) : (
                              <div className='userOrderPreferences'>
                                <div>
                                <FormGroup className="alert-set-destination">
                                  <div ref={this.buyNowRef}>
                                  {message && <Alert color="danger">
                                    {message}
                                  </Alert>}
                                  </div>
                                </FormGroup>
                                  {hasOptions ?
                                <FormGroup className="dropdownsContainer">
                                    <Input
                                        className='dropDowns'
                                        style={{ marginRight: '20px' }}
                                        onChange={e => this.handleOptionsChange(e)}
                                        type="select"
                                        name="country"
                                        id="q">
                                        <option selected disabled>
                                            Select size
                                        </option>
                                        {
                                            getVehicleDetail && getVehicleDetail.options.map((opt, ind) =>
                                                <option key={ind.toString()} value={opt.name}>
                                                    { opt.name }
                                                </option>
                                            )
                                        }
                                    </Input>
                                </FormGroup> : null}
                                </div>
                                <div className='additionalContainer'>
                                  { this.handleBuyNow(prodID, prodPrice, prodTitle, mainImage)}
                                </div>
                              </div>
                                )}

                          </Col>
                      </Row>

                      <div style={{ marginTop: 50, minHeight: '300px' }}>
                          <Row>
                              <Col sm={12} md={12} lg={12}>

                                  <Nav tabs>
                                      <NavItem>
                                          <NavLink
                                              activeTab={1}
                                              active={true}
                                              style={{ fontWeight: 'bold', color: 'black' }}

                                          >
                                              PRODUCT DETAIL
                                          </NavLink>
                                      </NavItem>
                                  </Nav>
                                      <TabContent activeTab style={{ paddingTop: '0px'}}>
                                          <Row>
                                              <Col style={{ marginTop: '10px', paddingBottom: '20px' }} sm="12">
                                              <div className='descriptionElement'>
                                                  <div className='descriptionContainer'>
                                                      { descr && descr.map((des) => <div>
                                                              {ReactHtmlParser(des)}
                                                          </div>)
                                                      }
                                                  </div>

                                              </div>
                                              </Col>
                                          </Row>
                                      </TabContent>

                              </Col>
                          </Row>
                          {/*</Row>*/}
                      </div>
                      </div>
                    <Snackbar
                      autoHideDuration={4000}
                      open={this.state.addToBagNotification}
                      onClose={this.handleHide}
                      message="Item Added"
                      key={"bottom center"}
                    />
                  </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }

  export default compose(
      withCookies,
      withRouter
  )(ProductDetailsComponent);
