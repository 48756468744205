import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { AvForm, AvGroup, AvInput, AvFeedback, AvField } from 'availity-reactstrap-validation';
import credentials from "../../../config/credentials";
import {Alert, Button, Card, CardBody, Label} from "reactstrap";
import LinearProgress from "@mui/material/LinearProgress";

export default function CheckoutForm(props) {
    // 1️⃣ Setup state to track client secret, errors and checkout status
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [cardAttempt, setCardAttempt] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState("");
    const [notification, setNotification] = useState("")
    const { order, onConfirm, onChange, cardHolder, journeyId, journeySubmission } = props;
    // 2️⃣ Store reference to Stripe
    const stripe = useStripe();
    const elements = useElements();
    useEffect(() => {
        // 3️⃣ Create PaymentIntent and fetch client secret as soon as the page loads
        const invoice_id = order[0]["invoice_id"]
        const paymentIntentURL = `${credentials.API_URL}/stripe-payment-intent/`
        window.fetch(paymentIntentURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ invoice_id: invoice_id }),
        }).then((res) => {
            return res.json();
        }).then((data) => {
            setClientSecret(data.clientSecret);
        });
    }, []);
    const handleChange = async (event) => {
        // 4️⃣ Listen for changes in the CardElement and display any errors as the customer types their card details
        if (event.complete === false) {
            if (cardAttempt === false) {
                const cardAttempt = journeySubmission(cardHolder, journeyId, "card-details")
                cardAttempt.then((res) => {
                    console.log("Payment intent")
                }).catch()
                setCardAttempt(true)
            }
        }
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };
    const handleSubmit = async (ev) => {

        ev.preventDefault();
        if (cardHolder === "") {
            setError(true);
            setNotification("Cardholder name is required")
            return;
        }
        setProcessing(true);

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        // 5. Confirm Card Payment.
        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
            },
        });
        if (payload.error) {
            setError(true);
            setNotification(`Payment failed. ${payload.error.message}`)
            setProcessing(false);
        } else {
            setError(false);
            setNotification("Card Payment Successful")
            onConfirm()
            // setProcessing(false);
        }
    };
    // Construct UI.
    return (
        <div>
            { processing ?
                <LinearProgress />
                :
                null
            }
            <Card className="bg-light" style={{padding: "0px 8px 90px 8px", textAlign: "center", marginBottom: "90px"}}>
                <CardBody>
                    <div className="col-lg-6 col-sm-12 offset-lg-3">
                        <br/>
                        <h4 className="page-header">
                            Card Payment
                        </h4>
                        <br/>
                        {notification &&
                        <div style={{padding: "16px 0px 8px 0px"}}>
                            <Alert color={error ? "danger" : "success"}>
                                {notification}
                            </Alert>
                        </div>
                        }
                        <AvForm>
                            <AvGroup>
                                <AvInput
                                    name="firstName"
                                    type="text"
                                    required
                                    onChange={onChange}
                                    placeholder="Cardholder name"
                                    maxLength={255}
                                />

                                <AvFeedback>Name is required</AvFeedback>
                            </AvGroup>
                        </AvForm>
                        <br/>
                        <form id="payment-form" onSubmit={handleSubmit}>
                            <div className="card-payment-field">
                                <CardElement
                                    id="card-element"
                                    // Specify styles here
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: '16px',
                                                color: '#424770',
                                                '::placeholder': {
                                                    color: '#aab7c4',
                                                },
                                                border: '2px solid black'
                                            },
                                            invalid: {
                                                color: '#9e2146',
                                            },
                                        },
                                    }}
                                    onChange={handleChange}
                                />
                            </div>
                            <div style={{padding: "40px 0px 16px 0px"}}>
                                <Button
                                    size={"sm"}
                                    disabled={processing || disabled || succeeded}
                                    className="delete-basket-button"
                                    id="submit">
                                    Pay
                                </Button>
                            </div>
                        </form>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
