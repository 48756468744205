import AIReportComponent from "../components/SearchPartnerEB/AIReportComponent";
import { connect } from "react-redux";
import { vehicleAction } from "../store/actions";

const mapStateToProps = state => {
  const {
    vehicleReducer: {
        getAIReportSuccess,
        getAIReportError,
        getAIReportLoader
    }
  } = state;
  return {
    getAIReportSuccess,
    getAIReportError,
    getAIReportLoader
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAIReport: payload =>
      dispatch(vehicleAction.getAIReport(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AIReportComponent);