import React, { Component } from 'react';
import Main from './components/MainComponent';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import { connect } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import persistor from './PersistStore'
// import MessengerCustomerChat from 'react-messenger-customer-chat'
import store from "./store/store";
import './App.css';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import {vehicleAction} from "./store/actions";
import {initGA} from "./components/Tracking"
import Fonts from "./Fonts"

const initialOptions = {
  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID_LIVE,
  currency: "GBP",
  intent: "capture",
};

class App extends Component {
  componentDidMount() {
    Fonts()
    this.props.getShippingMethods()
    initGA('G-GEXKDCNPBP')
  }


  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <PayPalScriptProvider options={initialOptions}>
            <BrowserRouter>
              <div>
                <Main />
                {/*<MessengerCustomerChat*/}
                {/*  pageId="102070134610567"*/}
                {/*  appId="242468800428892"*/}
                {/*/>*/}
              </div>
            </BrowserRouter>
          </PayPalScriptProvider>
        </PersistGate>
      </Provider>
    );
  }
}

const mapStateToProps = (state) => ({
  getShippingMethodsSuccess: state.getShippingMethodsSuccess
})

const mapDispatchToProps = dispatch => {
  return {
    getShippingMethods: payload =>
      dispatch(vehicleAction.getShippingMethods(payload))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(App);

