import React, { useRef, useState } from "react";
import {
  Alert,
  Button,
  Label,
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  CardSubtitle,
  CardTitle,
} from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvFeedback, AvField } from 'availity-reactstrap-validation';
import Typography from "@material-ui/core/Typography";
import { List, ListItem, Divider} from "@mui/material";

const ReportStepOneComponent = ({
                                  reg,
                                  onChange,
                                  prodOptSelect,
                                  onBuy,
                                  error,
                                  notification
}) => {

  const [selectedCard, setSelectedCard] = useState(null);
  const avFormRef = useRef(null);
  const [nextStageText, setNextStageText] = useState("");

  const handleCardSelect = (card) => {
    setSelectedCard(card);
    if (card === "free") {
      setNextStageText("Enter your email address below to access your free MOT report instantly.");
      prodOptSelect(reg, "free", )
    } else {
      setNextStageText("Access your report instantly after payment! Prefer a copy in your inbox? We’ll email it to you for added convenience.");
      prodOptSelect(reg, "premium")
    }
    avFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <div>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="static-info-page">
                  <div className="" style={{textAlign: 'center'}}>
                    {notification &&
                      <div style={{padding: "16px 0px 8px 0px"}}>
                        <Alert color={error ? "danger" : "success"}>
                          {notification}
                        </Alert>
                      </div>
                    }
                    <div className="col-lg-6 col-sm-12 offset-lg-3">
                      <Typography variant="h5" className="typo-text">
                        <span className="report-plate"> {reg} </span>
                      </Typography>
                      <br/>
                      <div>
                        <Row>
                          <Col>
                            <div className="free-v-premium"
                                 style={{
                                      border: selectedCard === "free" ? "2px solid black" : "1px solid whitesmoke",
                                      transition: "border 0.3s ease",
                                    }}
                            >
                              <Card>
                                <CardBody>
                                  <CardTitle>
                                    <h6 style={{ color: 'black'}} className="page-header">
                                      MOT Analysis
                                    </h6>
                                  </CardTitle>
                                  <CardSubtitle>
                                    <Typography style={{ color: '#3e87fd', fontSize: '14px'}}>
                                       Free
                                    </Typography>
                                  </CardSubtitle>
                                  <CardText>
                                    <List>
                                      <ListItem>
                                        <Typography variant="subtitle2">
                                          AI Vehicle Insights
                                        </Typography>
                                      </ListItem>
                                      <Divider/>
                                      <ListItem>
                                        <Typography variant="subtitle2">
                                          Free, instant summary of MOT history
                                        </Typography>
                                      </ListItem>
                                      <Divider/>
                                      <ListItem>
                                        <Typography variant="subtitle2">
                                          Understand vehicle's condition and reliability
                                        </Typography>
                                      </ListItem>
                                      <Divider/>
                                    </List>
                                  </CardText>
                                  <Button
                                    size={"lg"}
                                    className="choose-product-btn"
                                    onClick={() => handleCardSelect("free")}
                                  >
                                    Choose
                                  </Button>
                                </CardBody>
                              </Card>
                            </div>
                          </Col>
                          <Col>
                            <div
                              className="free-v-premium"
                              style={{
                                border: selectedCard === "premium" ? "2px solid black" : "1px solid whitesmoke",
                                transition: "border 0.3s ease",
                              }}
                            >
                              <Card>
                                <CardBody>
                                  <CardTitle>
                                    <h6 style={{ color: 'black'}} className="page-header"> Premium Report </h6>
                                  </CardTitle>
                                  <CardSubtitle style={{ color: '#f50357', fontSize: '14px'}}>
                                    <Typography>£2.99</Typography>
                                  </CardSubtitle>
                                  <CardText>
                                    <List>
                                      <ListItem>
                                        <Typography variant="subtitle2">Comprehensive Vehicle History</Typography>
                                      </ListItem>
                                      <Divider/>
                                      <ListItem>
                                        <Typography variant="subtitle2">MOT Analysis</Typography>
                                      </ListItem>
                                      <Divider/>
                                      <ListItem>
                                        <Typography variant="subtitle2">Stolen Check</Typography>
                                      </ListItem>
                                      <Divider/>
                                      <ListItem>
                                        <Typography variant="subtitle2">Outstanding Finance</Typography>
                                      </ListItem>
                                      <Divider/>
                                      <ListItem>
                                        <Typography variant="subtitle2">Mileage discrepancies</Typography>
                                      </ListItem>
                                      <Divider/>
                                      <ListItem>
                                        <Typography variant="subtitle2">Full plate-change history</Typography>
                                      </ListItem>
                                      <Divider/>
                                      <ListItem>
                                        <Typography variant="subtitle2">If it's recorded as scrapped</Typography>
                                      </ListItem>
                                      <Divider/>
                                    </List>

                                  </CardText>
                                  <Button
                                    size={"lg"}
                                    className="choose-product-btn"
                                    onClick={() => handleCardSelect("premium")}
                                  >
                                    Choose
                                  </Button>
                                </CardBody>
                              </Card>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <br/>
                      <br/>
                      <hr/>
                      <div ref={avFormRef} style={{paddingBottom: '72px'}}>
                        <AvForm  onValidSubmit={onBuy.bind(this, 3, selectedCard)} style={{padding: '20px'}}>
                          <div>
                            {/*<h4 className="page-header"> Your Details </h4>*/}
                            <Typography style={{textAlign: 'left'}}
                                        variant="body1">
                              {nextStageText}
                            </Typography>
                          </div>
                          <br/>
                          <AvGroup>
                            <Label for="userEmail" className="form-input">Email</Label>
                            <AvInput
                              name="userEmail"
                              type="email"
                              onChange={onChange}
                              required
                              placeholder="Your email address"
                              maxLength={255}
                            />
                            <AvFeedback>A valid email is required.</AvFeedback>
                          </AvGroup>
                          <br/>
                          <div className="col">
                            <Button size={"sm"} className="delete-basket-button">Next</Button>
                          </div>
                        </AvForm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr/>
      </div>
    </div>
  )
}

export default ReportStepOneComponent;