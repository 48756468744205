import React from 'react';
import {Button, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import {Loading} from "../LoadingComponent";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormControl } from '@mui/material';
import safeCheckout from '../../assets/images/safe-checkout.jpg';
import Typography from "@material-ui/core/Typography";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import {prodOptionsData} from "../../shared/promo_landing_data";

class ReportStepTwoComponent extends React.Component {

  renderBasketData(banana){
    return banana.map(
      ({ title, quantity, price_incl_tax }, i) => {
        return (
          <div style={{paddingBottom: '12px'}}>
            - {quantity}x {" "} Premium report for vehicle  <span style={{fontWeight: 'bold'}}>{title}</span> {" "}- {" "}
              <span className="sale-price" style={{fontWeight: "bold"}}>£{price_incl_tax}</span>
          </div>
        );
      }
    )
  }


  render() {

  const {state, toggle, onLogin, onLoading, onChange, onPayment} = this.props

  let message = ""

  if (state.modalMessage === "401") {
    message = "You're currently not logged in"
  } else {
    message = "Unable to proceed - Contact customer service, quoting the above error message"
  }

  let arr = Object.values(state.basket)
  return <div>

    <div>{this.props.name}</div>
      <div>
        <div>
          <Modal isOpen={state.showModal} toggle={toggle.bind(this,false)}>
            <ModalHeader toggle={toggle.bind(this,false)}>{state.modalMessage + " Error"}</ModalHeader>
            <ModalBody style={{ textAlign:'center'}}>
              {message}<p></p>
            </ModalBody>
            <ModalFooter>
              {message && message.includes("401") ? <Button color="primary"  onClick={onLogin.bind(this,false)}>Login</Button>
                : <Button color="primary"  onClick={toggle.bind(this,false)}>Okay</Button>
              }
            <Button color="secondary"  onClick={toggle.bind(this,false)}>Cancel</Button>
            </ModalFooter>
          </Modal>
        </div>
        <div className="col-lg-6 col-sm-12 offset-lg-3" style={{paddingBottom: "64px"}}>
          <div>
            <br/>
            <br/>
            <h4 className="page-header"> Premium Report </h4>
            <br/>
            <Typography style={{textAlign: "center"}}
                        variant="h5"
                        className="typo-text">
              <span className="report-plate"> {state.reg} </span>
            </Typography>
            <br/>
            {prodOptionsData[1].options && prodOptionsData[1].options.map((d, i) => {
              return (
                <div>
                  <Row>
                    <Col xs={5} style={{textAlign: 'right'}}>
                      {d.available
                        ? <CheckCircleOutlineRoundedIcon style={{fill: "green"}}/>
                        : <CancelOutlinedIcon style={{fill: "#c60000"}}/>
                      }

                    </Col>
                    <Col xs={7} style={{textAlign: 'left', fontWeight: 'bold', color: 'green'}}>
                      {d.item}
                    </Col>
                  </Row>
                </div>
              )
            })}
          </div>
          <br/>
          <hr className="checkout-info-block"/>
          <br/>
          <h5 className="page-header">
            Report Recipient:
          </h5>
          <div
            className="basket-page-information basket-page-information-bottom"
            style={{color: '#3e87fd'}}
          >
            {state.userEmail}
          </div>
          <br/>
          <hr className="checkout-info-block"/>
          <br/>
          <h5 className="page-header"> Your Order:</h5>
          <div className="basket-page-information basket-page-information-bottom"
               style={{color: '#000000', fontWeight: 500}}>
            {this.renderBasketData(arr)}
          </div>

          <hr className="checkout-info-block"/>
          <div className="text-center">
            <br/>
            <h5 className="page-header"> Payment Method:</h5>
            <Row className="payment-methods">
              <Col>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="payment"
                    // value={value}
                    onChange={onChange}
                  >
                    <FormControlLabel value="visa" control={<Radio/>} label="Visa"/>
                    <FormControlLabel
                      value="paypal"
                      control={<Radio />}
                      label="Paypal"
                    />
                  </RadioGroup>
                </FormControl>
              </Col>
              <Col>
                <img src={safeCheckout} alt="Safe checkout" height="75"/>
              </Col>
            </Row>
          </div>
          {onLoading ? (
              <div className="offset-1 offset-lg-1" style={{backgroundColor: '#f8f9fa'}}>
                <Loading/>
              </div>
            )
            :
            (
              <div className="col" style={{textAlign: 'center'}}>
                <Button
                  className="delete-basket-button"
                  size="sm"
                  onClick={onPayment.bind(this)}>
                  Next
                </Button>
              </div>
            )}
        </div>
        <br/>
      </div>
  </div>
    ;
  }
    }

    export default ReportStepTwoComponent;