import React, {useEffect, useState} from "react";
import { Row, Col, Card } from "reactstrap";
import {Helmet} from "react-helmet";
import Typography from "@material-ui/core/Typography";
import {Loading} from "../LoadingComponent"
import MotComponent from "./MotComponent";

export default function AIReportComponent(props) {
  const [notification, setNotification] = useState("");
  const [report, setReport] = useState(null);

   let vehImage = 'https://idaily-img.s3.eu-west-2.amazonaws.com/m-1u-05-09-24-11-26-29-exam-revision.jpg';

  useEffect(() => {
    setReport(null)
    props.getAIReport && props.getAIReport(props.match.params.q);
  }, [props.match.params.q]);

  useEffect(() => {
    // Handle loader and reset state
    if (props.getAIReportLoader) {
      setReport(null); // Clear old report while fetching new one
      setNotification("Loading the report...");
    } else if (props.getAIReportSuccess) {
      setReport(props.getAIReportSuccess);
      setNotification(""); // Clear notification on success
    } else if (props.getAIReportError) {
      setNotification("Report not found");
    }
  }, [props.getAIReportLoader, props.getAIReportSuccess, props.getAIReportError]);


  return (
    <div>
      <Helmet>
        <meta name="description" content="AI Vehicle Insights"/>
        <title>Reg Check Mate - UK</title>
      </Helmet>

      <section className="gen-section filter_space">
        <div
          className="container mobile_padding"
          style={{paddingTop: "40px", paddingBottom: "120px"}}
        >
          <div className="row">
            <div className="col-lg-12 col-sm-12">
                            {/* Loader */}
              {props.getAIReportLoader && <div><Loading/></div>}

              {/* Error Message */}
              {props.getAIReportError && <div>Error: {props.getAIReportError}</div>}

              {/* Report Content */}
              {report &&(
                <div>
                  <Row>
                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <div>
                        <div
                          style={{textAlign: 'center', fontSize: '1.5rem'}}
                          className="typo-text">
                          <span className="report-plate"> {props.match.params.q} </span>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <img
                          style={{height: '200px', paddingTop: '10px', paddingBottom: '15px'}}
                          src={vehImage}
                          alt="car image"
                        />
                      </div>
                    </Col>
                  </Row>
                  <br/>
                  <br/>
                  <Typography variant="h6" className="report-headers ai-headers">
                    AI MOT Insights
                  </Typography>
                  <br/>
                  <div className="report-div">
                  <Row className="failure-report">
                      <Typography variant="subtitle2 ai-headers">
                        {report.customer_perspective.caution}
                      </Typography>
                    </Row>
                    <br/>
                    <Row className="success-report">
                      <Typography variant="subtitle2 ai-headers">
                        { report.customer_perspective.positive }
                      </Typography>
                    </Row>
                    <br/>
                    <Row className="neutral-report">
                      <Typography variant="subtitle2 ai-headers">
                        { report.customer_perspective.recommendation }
                      </Typography>
                    </Row>
                  </div>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <Typography variant="h6" className="report-headers ai-headers">
                    AI Insights Details
                  </Typography>
                  <br/>
                  <div>
                    <Row>
                      <Col>
                        { report.good_points && report.good_points.map((point, index) => (
                          <div>
                            <Row className="success-report">
                              <Typography className="ai-text" variant="subtitle2">
                                {point}
                              </Typography>
                            </Row>
                            <br/>
                          </div>
                          ))
                        }
                      </Col>
                      <Col>
                        { report.bad_points && report.bad_points.map((point, index) => (
                          <div>
                            <Row className="failure-report">
                              <Typography className="ai-text" variant="subtitle2">
                                {point}
                              </Typography>
                            </Row>
                            <br/>
                          </div>
                          ))
                        }
                      </Col>
                    </Row>
                  </div>
                  <br/>
                  <br/>
                  <div>
                    <Typography variant="h6" className="report-headers ai-headers">
                      MOT History
                    </Typography>
                    <Row>
                      {report.mot_data && report.mot_data.map((d, i) => (
                        <div key={i} className="mot-component-container">
                          <MotComponent d={d} i={i} />
                        </div>
                      ))}
                    </Row>

                  </div>
                  {/*<pre>{JSON.stringify(report, null, 2)}</pre>*/}
                </div>
              )}

              {/* Default State */}
              {!props.getAIReportLoader &&
                !props.getAIReportError &&
                !props.getAIReportSuccess && <div>Getting the report...</div>}
            </div>
            </div>
          </div>
      </section>
    </div>
);
}