import React, {Component} from 'react';
import Tab from 'react-bootstrap/Tab';
import {Helmet} from "react-helmet";

import {
  Col,
  Row
} from "reactstrap";

class AboutUs extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Reg Check Mate - About Us</title>
          <link rel="canonical" href="https://www.reg-check-mate.co.uk/about-us" />
          <meta name="description"
                content="UK Premier Vehicle Check Service" />
        </Helmet>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="static-info-page">
                <div className="" style={{ textAlign: 'center' }}>
                  <h2 className="headings"
                      style={{ color: '#000000', padding: "0px 0px 32px 0px"}}>
                    Who we are
                  </h2>
                  {/*<hr className="fancy-line" />*/}
                  <div className="col-lg-6 col-sm-12 offset-lg-3">
                   <div className="help-department-heading stock-procurement-heading">
                   </div>
                  </div>
                  <br />
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                      <Col sm={12} className="">
                        <div style={{padding: "30px", paddingTop: "5px"}}>
                          {/*<p className="how-to-steps" style={{fontSize: '14px'}}>About Us</p>*/}
                            <p className="page-information">
                              Reg Check Mate, established in December 2020 in Leeds, West Yorkshire,
                              is the UK’s premier vehicle history check service. Leveraging advanced AI technology,
                              we deliver precise and comprehensive insights into vehicle histories,
                              uncovering critical details such as outstanding finance, MOT history,
                              mileage discrepancies, and accident records.
                            </p>
                            <p className="page-information">
                              Our innovative approach ensures buyers and sellers have the confidence to
                              make informed decisions with complete transparency.
                              Trusted by thousands, Reg Check Mate is your go-to solution for smarter, faster,
                              and more reliable vehicle history checks.
                            </p>
                        </div>
                      </Col>
                    </Row>
                  </Tab.Container>
                  <hr />
                </div>
              </div>
           </div>
           </div>
          </div>
        </section>
      </div>
    )
  }

}

export default AboutUs;
