import React, {useEffect, useState} from "react";
import { PayPalButtons, usePayPalScriptReducer  } from "@paypal/react-paypal-js";
import {Alert, Button, Card, CardBody, Label} from "reactstrap";
import credentials from "../../../config/credentials";

const PayPalCheckout = (props) => {
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  const [error, setError] = useState(false);
  const [notification, setNotification] = useState("")
  const [isDisabled, setIsDisabled] = useState(false);
  const { order, onConfirm, onLoading } = props;


  useEffect(() => {
    setIsDisabled(false);
  }, []);

  const onCreateOrder = (data, actions) => {
    const total = order[0]["amount"]["value"]
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: total,
          },
        },
      ],
    });
  }

  const onApproveOrder = (data, actions) => {
    return actions.order.capture().then((details) => {
      setIsDisabled(true);
      setError(false);
      setNotification("Transaction completed successfully.")
      onConfirm()
    });
  }

  const handleError = (err) => {
    setError(true);
    setNotification("An error occurred during the transaction. Please try again.");
  };

  return (
    <div>
      <Card>
        <CardBody
          className="bg-light"
          style={{padding: "0px 8px 90px 8px", textAlign: "center", marginBottom: "100px"}}>
          <div className="col-lg-6 col-sm-12 offset-lg-3">
            <br/>
            <h4 className="page-header">
              Complete Your Purchase
            </h4>
            <br/>
            {notification &&
              <div style={{padding: "16px 0px 8px 0px"}}>
                <Alert color={error ? "danger" : "success"}>
                  {notification}
                </Alert>
              </div>
            }
          </div>
          <div className="checkout" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {isPending || onLoading ? <p>LOADING...</p> : (
              <div style={{width: "70%"}}>
                <PayPalButtons
                  style={{layout: "vertical"}}
                  createOrder={(data, actions) => onCreateOrder(data, actions)}
                  onApprove={(data, actions) => onApproveOrder(data, actions)}
                  onError={(err) => handleError(err)}
                  disabled={isDisabled}
                />
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default PayPalCheckout;
